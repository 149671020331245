.Footer
  display flex
  flex-direction column
  align-items center
  justify-content center
  background-color $green
  background-image url('../img/green-bg.png')
  background-size cover
  background-position center
  background-repeat no-repeat
  height 100vh
  position relative
  @media (max-height: 750px) and (min-width: 1279px) and (orientation: landscape)
    & .Footer
      &__navigation
        width 100%
        bottom 30px
        flex-direction row
      &__call
        margin-bottom 32px
      &__up
        position absolute
        top 0.3em
        fontSzLh(16, 24)
      &__ant-logo
        margin 0 auto
  @media $mdMinus
    max-height 718px
  @media $sxs
    max-height 500px
    padding-top 56px
    justify-content flex-start
  &__logo
    width 190px
    height 62px
    margin-top -140px
    margin-bottom 120px
    @media $lgMinus
      margin-top 0
    @media $laptopMinus
      margin-bottom 140px
    @media $mdMinus
      margin-bottom 120px
    @media $sxs
      margin-bottom 69px
  &__link
    font-weight 100
    fontSzLh(120, 120)
    text-transform uppercase
    margin-bottom 24px
    opacity 1
    transition(opacity)
    +hover()
      opacity 0.7
    @media $laptopMinus
      fontSzLh(100, 100)
    @media $mdMinus
      fontSzLh(56, 56)
    @media $sxs
      fontSzLh(32, 38)
      margin-bottom 8px
  &__call
    font-weight 300
    fontSzLh(24, 38)
    text-transform uppercase
    margin-bottom 64px
    opacity 1
    transition(opacity)
    +hover()
      opacity 0.7
    @media $mdMinus
      fontSzLh(20, 28)
      margin-bottom 60px
    @media $sxs
      fontSzLh(16, 24)
      margin-bottom 48px
  &__title
    font-weight 400
    fontSzLh(16, 24)
    text-transform uppercase
    margin-bottom 8px
  &__text
    font-weight 300
    fontSzLh(24, 38)
    text-transform uppercase
    @media $laptopMinus
      margin-bottom 38px
    @media $mdMinus
      fontSzLh(32, 48)
      margin-bottom 78px
    @media $sxs
      margin-bottom 40px
      fontSzLh(24, 34)
  &__pdf
    font-weight 300
    fontSzLh(20, 28)
    text-transform uppercase
    position absolute
    left 24px
    bottom 36px
    opacity 1
    transition(opacity)
    @media $mdMinus
      fontSzLh(16, 24)
      bottom 24px
    @media $sxs
      display none
    +hover()
      opacity 0.7
  &__up
    font-weight 300
    fontSzLh(20, 28)
    text-transform uppercase
    right 24px
    bottom 36px
    opacity 1
    transition(opacity)
    @media $mdMinus
      fontSzLh(16, 24)
      bottom 24px
    @media $sxs
      right 12px
      bottom 28px
    +hover()
      opacity 0.7
  &__ant-logo
    width 34px
    height 34px
    cursor pointer
    transition(opacity)
    img
      width 100%
      height 100%
      object-fit contain
    +hover()
      opacity 0.5
    @media $mdMinus
      bottom 24px
    @media $sxs
      left 12px
      transform unset
  &__navigation
    display flex
    position absolute
    align-items center
    flex-direction column
    left 50%
    transform translateX(-50%)
    bottom 18px
    gap 24px
    @media $laptopMinus
      gap 16px
      bottom 26px
    @media $mdMinus
      gap 8px
      bottom 24px
    @media $mdMinus
      bottom 20px
