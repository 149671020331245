.Preloader
  position fixed
  width 100vw
  height calc(var(--vh, 1) * 100)
  background-color $green
  z-index 10000000
  pointer-events all
  top -100%
  left 0
  transition-property top
  transition-duration 700ms
  transition-timing-function ease-in-out
  &__wrapper
    display flex
    justify-content center
    align-items center
    position relative
    width 100%
    height 100%
  &__curtain
    width 100%
    mask-image linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,0) 20%, rgba(0,0,0,1) 60%)
    mask-size 200% auto
    mask-repeat no-repeat
    animation 1.7s linear 1 forwards curtainLoading 
    text-align center
  &__logo
    width 60vw
    height auto
  &-active
    top 0
    

@keyframes curtainLoading
  0%
    mask-position 170% 0
  100%
    mask-position 0% 0
    
