.Legacy
  position relative
  padding-top 120px
  padding-bottom 80px
  display flex
  flex-direction column
  background-color $white
  background-image url('../img/bg.png')
  background-size contain
  background-position center
  background-repeat repeat

  @media $mdMinus
    padding-top 80px

  @media $sxs
    padding-top 40px
    padding-bottom 40px

  &__title
    fontSzLh(120, 120)
    font-weight 100
    color $green
    text-transform uppercase

    @media $laptopMinus
      fontSzLh(100, 100)

    @media $mdMinus
      fontSzLh(56, 56)

    @media $sxs
      fontSzLh(30, 32)

    &:nth-child(1)
      margin-left 24px

      @media $mdMinus
        margin-left 0
        text-align center

      @media $sxs
        text-align left
        margin-left 12px

    &:nth-child(2)
      margin-left auto
      margin-right 24px
      margin-bottom 80px

      @media $laptopMinus
        margin-bottom 40px

      @media $mdMinus
        margin-left 0
        margin-right 0
        text-align center

      @media $sxs
        text-align left
        margin-left 12px
        margin-bottom 16px

  &__title-part
    display inline-block

  &__text
    fontSzLh(20, 32)
    font-weight 400
    color $green
    margin-left 351px
    max-width 724px
    margin-bottom 48px

    @media $lgMinus
      margin-left 300px

    @media $mdMinus
      margin-left 0
      text-align center
      max-width 472px
      fontSzLh(16, 24)
      margin 0 auto
      margin-bottom 62px

    @media $sxs
      margin 0
      margin-left 12px
      margin-bottom 36px
      text-align left

  &__slider
    padding-left 351px
    position relative

    @media $lgMinus
      padding-left 300px

    @media $mdMinus
      padding-left 0

  .swiper-container
    padding-right 24px

    @media $mdMinus
      padding-left 24px

    @media $sxs
      padding-left 12px
      padding-right 12px

  &__slide
    background $white
    width 520px
    max-width 520px
    height 661px
    padding-top 58px
    padding-bottom 75px
    display flex
    flex-direction column
    align-items center

    @media $sxs
      width 296px
      max-width 296px
      height 536px
      padding 40px 16px 32px

    &-title
      fontSzLh(48, 58)
      font-weight 300
      color $green
      margin-bottom 42px

      @media $sxs
        fontSzLh(24, 24)
        margin-bottom 24px

      img
        height 48px

        @media $sxs
          height: 24px
          display: block

    &-img
      width 340px
      height 360px
      object-fit cover
      margin-bottom 32px

      @media $sxs
        width 232px
        height 320px
        margin-bottom 16px

    &-text
      fontSzLh(16, 20)
      font-weight 300
      color $green
      margin-bottom 16px
      max-width 385px
      text-align center
      text-transform uppercase

      @media $sxs
        margin-bottom 8px

  &__arrows
    display flex
    flex-direction column
    position absolute
    left 75px
    top 50%
    transform translateY(-50%)

    @media $lgMinus
      left 50px

    @media $mdMinus
      left unset
      right 12px
      top -40px
      transform unset
      flex-direction row
      align-items center

    @media $sxs
      top -30px

    .SliderArrows__arrow
      width 200px
      height 200px
      border 1px solid #DDE8E6
      border-radius 50%
      display inline-flex
      justify-content center
      align-items center
      cursor pointer
      z-index 2
      transition 2s
      -webkit-user-select none
      position relative

      @media $mdMinus
        width 33px
        height 14px
        border none

      @media $sxs
        width 28px
        height 12px

      &.disabled
        border 1px solid #575756
        pointer-events none
        opacity .3

        @media $mdMinus
          border none

        .SliderArrows__arrow-icon
          svg
            fill #575756

      &-icon
        width 48px
        height 15px
        display flex
        justify-content center
        align-items center

        @media $mdMinus
          width 33px
          height 14px

        @media $sxs
          width 28px
          height 12px

        svg
          width 48px
          height 15px
          fill $green

          @media $mdMinus
            width 33px
            height 14px

          @media $sxs
            width 28px
            height 12px

      &._prev
        margin-bottom 24px

        @media $mdMinus
          margin-bottom 0
          margin-right 16px

        .SliderArrows__arrow-icon
          transform rotate(180deg)

      .circle
        position absolute
        width 202px
        height 202px
        left 0
        top -4px
        right 0
        bottom 0
        transform rotate(-90deg)

        @media $mdMinus
          display none

      +hover()
        .circle-inner
          stroke-dashoffset 0

      .circle-inner
        stroke-dasharray 631.59px 631.59px
        stroke-dashoffset 631.59px
        transition 2s
        width 100%
        height 100%