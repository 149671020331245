.Architecture
  position relative
  overflow hidden
  background url(../img/bg.png)

  &__content
    padding-top 160px
    @media $mdMinus
      padding-top 80px
    @media $sxs
      padding-top 64px

  &__title
    text-align center
    fontSzLh(120, 120)
    font-weight 50
    color $green

    @media $laptopMinus
      fontSzLh(100, 100)

    @media $mdMinus
      fontSzLh(56, 56)

    @media $sxs
      text-align initial
      fontSzLh(30, 32)

  &__slider
    position relative
    margin 120px auto 0
    @media $laptopMinus
      margin-top 80px
      width calc(100% - 180px)
    @media $mdMinus
      width 100%
      height 400px
      margin-top 78px
    @media $sxs
      height 200px
      margin-top 52px

  &__slider-arrow
    display flex
    justify-content center
    align-items center
    position absolute
    z-index 2
    top calc(50% - 80px)
    width 160px
    height 160px
    overflow hidden
    border-radius 50%
    color #fff
    background-color $green
    transition all $transition

    +hover()
      background-color $lightGreen

    @media $mdMinus
      top auto
      bottom calc(100% + 13px)
      width 33px
      height 30px
      border-radius 0
      color $green !important
      background-color transparent !important

    @media $sxs
      width 28px

    &._prev
      left 24px
      @media $laptopMinus
        left -64px
      @media $mdMinus
        justify-content flex-start
        left auto
        right 50px

      @media $sxs
        right 36px

    &._next
      right 24px
      @media $laptopMinus
        right -64px
      @media $mdMinus
        justify-content flex-end
        right 0

    svg
      flex 0 0 48px
      height 16px

      @media $mdMinus
        flex 0 0 44px
        height 14px

      @media $sxs
        flex 0 0 37px
        height 12px

  &__slide
    position relative

    &::after
      display block
      content ''
      width 100%
      padding-top 800px

      @media $mdMinus
        padding-top 66.66%

    img
      position absolute
      left 0
      top 0
      display block
      width 100%
      height 100%
      object-fit cover

  &__texts
    position relative
    margin-top 80px
    text-align center
    font-weight 300
    color $green

    @media $laptopMinus
      margin-top 56px

    @media $mdMinus
      margin-top 32px

    @media $sxs
      margin-top 24px

    &::before
      display block
      content ''
      position absolute
      pointer-events none
      top 50%
      left 50%
      width 1140px
      height 1514px
      // background-image url('/img/text-bg.png')
      background-size cover
      background-position center
      transform translateX(-50%) translateY(-50%)

      @media $sxs
        // background-image url('/img/text-bg-mobile.png')
        width 768px
        height 960px
        transform translateX(-50%) translateY(-40%)

  &__quote-img
    @media $laptopMinus
      width 34px

    @media $sxs
      width 25px

  &__quote
    max-width 1100px
    margin 32px auto 0
    fontSzLh(40, 56)
    text-transform uppercase

    @media $laptopMinus
      fontSzLh(48, 64)

    @media $mdMinus
      max-width 590px
      margin-top 34px
      fontSzLh(24, 38)

    @media $sxs
      margin-top 11px
      fontSzLh(20, 28)
      text-align initial

  &__quote-author
    margin-top 80px
    fontSzLh(20, 32)
    text-transform uppercase

    @media $laptopMinus
      margin-top 40px

    @media $mdMinus
      margin-top 23px
      fontSzLh(16, 24)

    @media $sxs
      margin-top 16px
      fontSzLh(14, 20)

#architecture-slider
  height 100%
