.Menu
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background-color $green
  background-image url('../img/green-bg.png')
  background-size cover
  background-position center
  background-repeat no-repeat
  z-index 3
  display none

  &__inner
    display flex
    justify-content center
    align-items center
    height 100%

  &__logo
    width 138px
    height 45px
    object-fit contain
    position absolute
    top 48px
    left 50%
    transform translateX(-50%)

    @media $mdMinus
      width 160px
      height 52px
      top 32px

    @media $sxs
      width 97px
      height 32px
      top 24px

  &__phone, &__close, &__pdf
    opacity 1
    transition(all)

    +hover()
      opacity .7
      transition(all)

  &__phone, &__close, &__address, &__pdf
    position absolute
    font-weight 300
    fontSzLh(20, 28)
    text-transform uppercase

    @media $sxs
      fontSzLh(16, 24)

  &__phone
    top 58px
    right 24px

    @media $mdMinus
      top 46px

    @media $sxs
      &:not(._icon)
        display none

    &._icon
      display none
      width 32px
      height 32px

      @media $sxs
        display block
        top 24px
        right 12px

  &__close
    top 54px
    left 24px
    fontSzLh(24, 32)

    @media $laptopMinus
      top 58px

    @media $mdMinus
      top 46px
      fontSzLh(20, 28)

    @media $sxs
      top 32px
      left 12px
      fontSzLh(16, 24)

  &__address
    bottom 36px
    left 24px

    @media $mdMinus
      bottom 20px

    @media $sxs
      display none

  &__pdf
    bottom 36px
    right 24px

    @media $mdMinus
      bottom 20px

    @media $sxs
      display none

  &__list
    counter-reset point
    margin-left 110px

    @media $mdMinus
      margin-left 40px

    @media $sxs
      margin-left 0

    +hover()
      .Menu__list-item
        opacity .4
        transition(all)

    &-item
      transition(all)

      // &:nth-child(1)
      //   margin-left 63px

      //   @media $sxs
      //     margin-left 40px

      &:nth-child(1)
        @media $sxs
          margin-left 0

      &:nth-child(2)
        margin-left -91px

        .Menu__list-link
          gap 55px

        @media $mdMinus
          margin-left 40px

        @media $sxs
          margin-left 10px

      &:nth-child(3)
        @media $mdMinus
          margin-left -20px

        @media $sxs
          margin-left 60px

      &:nth-child(4)
        margin-left -45px

        .Menu__list-link
          gap 10px

        @media $mdMinus
          margin-left 30px

      &:nth-child(5)
        margin-left 91px

        @media $mdMinus
          margin-left 40px

      +hover()
        opacity 1 !important
        transition(all)

        .Menu__list-link
          .Menu__list-text
            transform translateX(10px)

            &:before
              opacity 1 !important
              left -48px
              transition(all)

          .Menu__list-img
            opacity 1
            transition(all)

      &:not(:last-child)
        margin-bottom 16px

        @media $mdMinus
          margin-bottom 8px

        @media $sxs
          margin-bottom 4px

    &-link
      display flex
      align-items center
      gap 34px

      .Menu__list-text
        position relative
        transition(all)
        font-weight 100
        fontSzLh(64, 64)

        @media $mdMinus
          fontSzLh(64, 80)

        @media $xs
          fontSzLh(58, 64)

        @media $sxs
          fontSzLh(32, 48)

        @media $mini
          fontSzLh(26, 38)

        &:before
          content counter(point, upper-roman)
          counter-increment point 1
          position absolute
          left -38px
          bottom 4px
          font-weight 300
          fontSzLh(20, 24)
          opacity .4
          transition(all)

          @media $sxs
            opacity 1
            left -25px
            fontSzLh(16, 24)

    &-img
      width 140px
      height 72px
      object-fit cover
      opacity 0
      transition(all)
      z-index 1

      @media $mdMinus
        display none