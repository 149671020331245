.Garden
  position relative
  z-index 2
  background url('../img/bg.png')

  &__content
    padding 160px 0
    color $green

    @media $mdMinus
      padding 80px 0

    @media $sxs
      padding 40px 0

  &__title
    text-align center
    font-weight 50
    fontSzLh(120, 120)

    @media $laptopMinus
      fontSzLh(100, 100)

    @media $mdMinus
      fontSzLh(56, 56)

    @media $sxs
      text-align initial
      max-width 300px
      fontSzLh(30, 32)

  &__title-part
    display block

    @media $sxs
      display inline

  &__description
    max-width 735px
    margin 60px auto 0
    fontSzLh(20, 32)
    text-align center

    @media $laptopMinus
      margin-top 40px

    @media $mdMinus
      max-width 472px
      margin-top 32px
      fontSzLh(16, 24)

    @media $sxs
      text-align initial
      margin-top 16px

  &__slider
    width 100%
    position relative
    margin 80px auto 0
    @media $laptopMinus
      width calc(100% - 180px)
    @media $mdMinus
      width 100%
    @media $sxs
      margin-top 64px

  &__slide
    display flex
    flex-direction column
    width 100%

    @media $mdMinus
      display block

  &__slide-image
    height 800px
    width 100%
    @media $mdMinus
      height 400px
    @media $sxs
      height 240px
    img
      objectFit(100%, cover)

  &__slide-caption
    margin 16px 24px 0
    fontSzLh(24, 38)
    font-weight 300
    text-transform uppercase
    text-align center

    @media $mdMinus
      margin 16px 0 0
      fontSzLh(16, 24)
      text-align center

    br
      @media $mdMinus
        display none

  &__slider-controls
    display flex
    justify-content space-between
    position absolute
    left 24px
    right 24px
    width auto
    column-gap 8px
    z-index 2
    bottom 50%
    transform translateY(50%)
    @media $laptopMinus
      left -90px
      right -90px
    @media $mdMinus
      column-gap 18px
      left auto
      right 0
      bottom calc(100% + 30px)

    @media $sxs
      column-gap 8px

  &__slider-arrow
    display flex
    justify-content center
    align-items center
    width 160px
    height 160px
    overflow hidden
    color $green
    // border 1px solid $green
    border-radius 50%
    color #FFF
    background-color $green
    transition all $transition

    @media $mdMinus
      width 33px
      height 30px
      border-radius 0
      color $green !important
      border none
      background-color transparent !important

    @media $sxs
      width 28px

    +hover()
      background: #337870

    &._prev
      @media $mdMinus
        justify-content flex-start

    &._next
      @media $mdMinus
        justify-content flex-end

    svg
      flex 0 0 48px
      height 16px

      @media $mdMinus
        flex 0 0 44px
        height 14px

      @media $sxs
        flex 0 0 37px
        height 12px

#garden-slider
  height 100%
