.Portal
  padding 0 24px
  padding-top 160px
  display flex
  flex-direction column
  background url(../img/bg.png)
  @media $mdMinus
    padding 0 24px
    padding-top 80px
  @media $sxs
    padding 0 12px
    padding-top 64px

  &__title
    fontSzLh(120, 120)
    font-weight 100
    color $green
    text-transform uppercase
    margin-bottom 80px

    @media $laptopMinus
      fontSzLh(100, 100)

    @media $mdMinus
      margin-bottom 32px
      fontSzLh(56, 56)
      text-align left

    @media $sxs
      margin-bottom 24px
      max-width 280px
      fontSzLh(30, 32)

  &__title-part
    display block

    @media $sxs
      display inline

    &:not(:first-child)
      margin-top 16px

      @media $mdMinus
        margin-top 0

    &:nth-child(2)
      text-align right

      @media $laptopMinus
        text-align left

    &:nth-child(3)
      @media $laptopMinus
        max-width 750px
        margin-left auto
        text-align right

      @media $mdMinus
        max-width 100%
        margin-left 0
        text-align left

  &__wrapper
    display grid
    grid-template-rows 354px 1fr auto
    grid-template-columns 1fr 1000px
    grid-template-areas 'small-img big-img' 'text big-img' 'subtext big-img'
    gap 80px 0
    @media $laptopMinus
      gap 64px 48px
      grid-template-rows 354px auto auto
      grid-template-columns repeat(2, minmax(0, 1fr))
      grid-template-areas 'small-img big-img' 'text big-img' 'subtext subtext'
    @media $mdMinus
      display flex
      flex-direction column
      gap 32px
    @media $sxs
      gap 24px


  &__text
    grid-area text
    fontSzLh(40, 56)
    font-weight 300
    color $green
    text-transform uppercase

    @media $mdMinus
      fontSzLh(24, 38)
      text-align left

    @media $sxs
      fontSzLh(20, 28)

  &__subtext
    grid-area subtext
    fontSzLh(24, 34)
    font-weight 400
    color $green
    max-width 80%
    @media $mdMinus
      text-align center
      margin 0 64px
      max-width unset
      fontSzLh(16, 24)
    @media $sxs
      margin 0
      text-align initial

  &__img
    & img
      objectFit(100%, cover)

    &._small
      grid-area small-img
      // width 354px
      // height 354px
      height 100%
      aspect-ratio 1
      @media $mdMinus
        display none

    &._big
      grid-area big-img
      width 100%
      height 1480px
      @media $laptopMinus
        height 1106px
      @media $mdMinus
        overflow hidden
        max-width 100%
        height 720px
      @media $sxs
        max-width unset
        height 348px
      & .simpleParallax
        height 100%
