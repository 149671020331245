.SuccessPopup
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background-color rgba(#02221E, 0.89)
  justify-content center
  align-items center
  z-index 3
  display none
  @media $laptopMinus
    padding 0 12px
  @media $mini
    padding 0
    min-height calc(var(--vh, 1vh) * 100)
  &__content
    display flex
    flex-direction: column
    justify-content center
    align-items center
    min-height 100%
  &__card
    width 100%
    max-width 1148px
    background #F5F5F5
    position relative
    display flex
    @media $laptopMinus
      flex-direction column
      max-width 468px
    @media $mini
      flex 1
      min-height 100%
  &__close
    position absolute
    top 16px
    right 16px
    width 80px
    height 80px
    border 1px solid #DDE8E6
    border-radius 50%
    display inline-flex
    justify-content center
    align-items center
    cursor pointer
    z-index 2
    transition 2s
    -webkit-user-select none
    &-icon
      width 24px
      height 24px
      @media $laptopMinus
        width 16px
        height 16px
    @media $laptopMinus
      width 48px
      height 48px
      background $white
      border none
    svg
      position absolute
      left -2px
      top -2px
      right 0
      bottom 0
      transform rotate(-90deg)
      @media $laptopMinus
        display none
    +hover()
      .circle
        stroke-dashoffset 0
    .circle
      stroke-dasharray 301.59px 301.59px
      stroke-dashoffset 301.59px
      transition 2s
  &__image
    background-image url('../img/success-desktop.png')
    background-size cover
    background-position center
    background-repeat no-repeat
    width 100%
    max-width 460px
    @media $laptopMinus
      background-image url('../img/callme-mobile.png')
      max-width 100%
      height 200px
      min-height 200px
  &__form
    display flex
    flex-direction column
    align-items center
    justify-content center
    text-align center
    width 100%
    padding 175px 0
    @media $laptopMinus
      padding 88px 12px
    @media $mini
      height max-content
      margin-top auto
      margin-bottom auto
    &-title
      font-weight 300
      fontSzLh(48, 56)
      margin-bottom 16px
      color $green
      text-transform uppercase
      @media $laptopMinus
        fontSzLh(24, 28)
        margin-bottom 8px
    &-text
      font-weight 300
      fontSzLh(16, 28)
      color $green
      text-transform uppercase
      margin-bottom 80px
      max-width 465px
      text-align center
      @media $laptopMinus
        max-width 296px
        fontSzLh(14, 22)
        margin-bottom 20px