@css {
  .Header {
    transition: background-color .3s ease, opacity .3s ease;
  }

  .Header__logo {

  }

  .Header__logo svg {
    transition: all 0.8s ease 1.3s, color 0.2s ease;
  }

  .Header__logo-bg {
    transition: transform 1.1s ease 1.3s, opacity 0s linear 2.4s;
  }

  .Header__logo-curtain::after {
    transition: left .9s ease 0s, opacity 0.2s linear 0.7s;
  }
}

.Header
  position fixed
  top 0
  left 0
  right 0
  z-index 5
  transition background-color .3s ease

  @media $mdMinus
    background-color #fff

  &__content
    display flex
    justify-content space-between
    align-items center
    padding-top 54px

    @media $laptopMinus
      padding-top 58px

    @media $mdMinus
      padding-top 38px
      padding-bottom 30px

    @media $sxs
      padding-top 24px
      padding-bottom 20px

  &__logo
    transition all .3s ease

    @media $mdMinus
      color $green

    svg
      display block
      position fixed
      z-index 2
      top calc(50% - 15vw)
      left 15vw
      width 70vw
      height 30vw

  &__logo-curtain
    pointer-events none
    position fixed
    z-index 3
    top 0
    left 0
    width 100%
    height 100%
    overflow hidden

    &::after
      display block
      content ''
      position absolute
      z-index 2
      top 0
      left 0
      width 100%
      height 100%
      background-color $green
      box-shadow 50px 50px 500px 0 $green

  &__logo-bg
    pointer-events none
    position fixed
    z-index 1
    top 0
    left 0
    width 100%
    height 100%
    overflow hidden
    background-color $green

  &__link
    font-weight 400
    fontSzLh(24, 32)
    text-transform uppercase
    transition(all)

    +hover()
      opacity .7
      transition(all)

    @media $mdMinus
      fontSzLh(20, 28)
      color $green

    @media $sxs
      fontSzLh(16, 24)

      &._phone
        &:not(._icon)
          display none

    &._phone._icon
      display none
      width 32px
      height 32px
      color #fff

      @media $mdMinus
        color $green

      @media $sxs
        display block

      svg
        width 100%
        height 100%

.Header[data-color=green]
  @media $mdPlus
    .Header
      &__link
        color $green

      &__logo
        color $green

.Header._page-at-top
  @media $mdMinus
    background-color transparent

    .Header
      &__link, &__link._phone._icon
        color #fff

      &__logo
        color #fff

.Header._animated:not(._page-at-top)
  @media $mdPlus
    .Header
      &__logo
        opacity 0
        pointer-events none

      // &__link._phone
      //   opacity 0
      //   pointer-events none

.Header._above-time-panel
  @media $mdPlus
    .Header__link._menu
      color $green

.Header._hidden
  opacity 0
  pointer-events none

.Header._animated
  .Header
    &__logo
      svg
        top 51px
        left calc(50% - 50px)
        width 100px
        height 33px

        @media $laptopMinus
          top 54px

        @media $mdMinus
          left calc(50% - 80px)
          width 160px
          height 53px
          top 20px

        @media $sxs
          top 34px
          left calc(50% - 48px)
          width 96px
          height 33px
          top 20px

    &__logo-curtain
      &::after
        left 100%
        opacity 0

    &__logo-bg
      opacity 0
      transform translateY(-100%)
