.Residence
  position relative
  transform none !important

  &::-webkit-scrollbar
    display none

  &__scroller
    display flex
    -ms-overflow-style none
    max-height 100vh

    @media $mdMinus
      flex-direction column
      height 100%
      max-height unset
      overflow-x hidden
      overflow-y unset

  &__bg
    position fixed
    contain paint
    z-index -1
    inset auto 0 0 0
    width 100%
    height 100vh
    background-image url('../img/residence-3.jpg')
    background-size cover
    background-position left top
    background-repeat no-repeat
    transition background-image 500ms ease-in-out
    &:after
      // Images preloader
      position: absolute
      z-index -10
      content: url('../img/residence-3.jpg') url('../img/residence-2.png')
      visibility hidden
    @media $mdMinus
      background-position center top
    @media $xs
      inset 0 0 0 0
    @media $sxs
      background-position -200vw top

  & > section
    position relative
    z-index 2

  &__parallax-img
    display block
    position absolute
    bottom 0
    left -30%
    width 130%
    max-width none

    @media $mdMinus
      position static
      width 100%
      height 100%
      // max-width 150%
      // max-height 150%
      object-fit cover

  &__title
    display flex
    position relative
    flex-direction column
    justify-content flex-start
    align-items center
    &-top
      position absolute
      fontSzLh(40, 56)
      font-weight 300
      top -120px
      text-align center
      transform translateY(-100%)
      @media $lgMinus
        top -80px
      @media $laptopMinus
        top -48px
      @media $mdMinus
        display none
    &-main
      position relative
      fontSzLh(120, 120)
      font-weight 100
      text-align center
      z-index 2
      @media $laptopMinus
        fontSzLh(100, 100)
      &:not(._mobile)
        @media $mdMinus
          display none
      &._mobile
        display none
        @media $mdMinus
          display block
          fontSzLh(56, 56)
          margin 0 auto
          margin-bottom 48px
          max-width 592px
        @media $sxs
          fontSzLh(30, 32)
          max-width 296px
          margin-bottom 32px
      span
        display block

  &__slide
    display flex
    flex-direction column
    justify-content center
    align-items center
    min-width 100vw
    height 100vh
    position relative
    @media $mdMinus
      padding-top 80px
      padding-bottom 80px
      margin-right 0
      min-width unset
      height unset
      position relative
    @media $sxs
      padding-top 40px
      padding-bottom 16px

  &__privacy
    &-wrapper
      display flex
      gap 32px
      position absolute
      left calc(100% - 277px)
      @media $laptopMinus
        right -500px

      @media $mdMinus
        gap 48px
        position unset
        right unset
        top unset
        transform unset
        flex-direction column
        align-items center

      @media $sxs
        gap 32px

    &-img
      position relative
      flex-shrink 0
      width 755px
      height 800px
      overflow hidden

      @media $lgMinus
        width 595px
        height 620px

      @media $laptopMinus
        width 378px
        height 440px

      @media $mdMinus
        width 480px
        height 480px

      @media $sxs
        width 296px
        height 300px

    &-subtext
      flex-shrink 0
      fontSzLh(20, 32)
      font-weight 400
      max-width 345px

      @media $lgMinus
        fontSzLh(24, 34)

      @media $laptopMinus
        fontSzLh(20, 32)

      @media $mdMinus
        fontSzLh(16, 24)
        max-width 480px
        margin-top 0
        text-align center

      @media $sxs
        padding 0 12px
        text-align left

  &__plan
    min-width 100vw
    position relative

    @media $laptopMinus
      min-width 1680px

    @media $mdMinus
      min-width unset

    &-img
      position absolute
      overflow hidden

      @media $mdMinus
        position unset

      @media $xs
        margin-bottom 180px

      @media $sxs
        display none

      &._big
        width 555px
        height 555px
        top 0
        right 17%

        @media $laptopMinus
          right 30%

        @media $mdMinus
          width 440px
          height 294px
          left 0
          margin-bottom 192px
        @media $smMinus
          width 376px
          height 294px

      &._small
        width 346px
        height 442px
        right -172px
        bottom 92px
        @media $lgMinus
          bottom 0
        @media $laptopMinus
          right 0
        @media $mdMinus
          top 174px
          right 24px
          width 224px
          height 224px
          position absolute
        @media $xs
          right 0

    &-text
      fontSzLh(32, 48)
      font-weight 300
      max-width 724px
      position absolute
      top 508px
      right 17%
      text-transform uppercase

      @media $lgMinus
        max-width 600px
      @media $laptopMinus
        max-width 724px
        fontSzLh(24, 38)
        bottom unset
        top 420px
        right 27%

      @media $mdMinus
        position unset
        bottom unset
        left unset
        margin 0 auto
        margin-bottom 80px
        padding 0 24px

      @media $sxs
        fontSzLh(20, 28)
        padding 0 12px
        margin-bottom 40px

  &__relax
    min-width 100vw
    position relative

    @media $laptopMinus
      min-width 1690px

    @media $mdMinus
      min-width unset
      display flex
      flex-direction column
      align-items center
      text-align center

    &-title
      fontSzLh(120, 120)
      font-weight 100
      max-width 1100px
      position relative
      z-index 2

      span
        display inline-block

      @media $laptopMinus
        margin-left -52px
        fontSzLh(100, 100)

      @media $mdMinus
        margin-left unset
        fontSzLh(56, 56)
        max-width 475px
        text-align center

      @media $sxs
        fontSzLh(30, 32)
        padding 0 12px
        text-align left

      &:nth-child(1)
        margin-top 200px

        @media $lgMinus
          margin-top 120px

        @media $mdMinus
          margin-top 0
          margin-left 0

      &:nth-child(2)
        margin-left 296px
        margin-bottom 60px

        @media $laptopMinus
          margin-bottom 38px
          margin-left 80px

        @media $mdMinus
          margin-bottom 32px
          margin-left unset

        @media $xs
          margin-bottom 48px

        @media $sxs
          margin-bottom 16px
          text-align left
          width 100%

    &-text
      display block
      fontSzLh(20, 32)
      font-weight 400
      max-width 560px
      margin-left 296px
      position relative
      z-index 2

      @media $lgMinus
        fontSzLh(24, 34)

      @media $laptopMinus
        fontSzLh(20, 32)
        max-width 600px
        margin-right 24px
        margin-left 80px

      @media $mdMinus
        fontSzLh(16, 24)
        max-width 480px
        margin-right 0
        margin-left 0
        margin-bottom 48px

      @media $xs
        margin-bottom 48px

      @media $sxs
        margin-bottom 32px
        // max-width 296px
        padding 0 12px
        text-align left

    &-img
      overflow hidden

      &._big
        width 733px
        height 100%
        position absolute
        top 0
        right 0

        @media $mdMinus
          width 480px
          height 638px
          position unset
          top unset
          right unset
          margin-bottom 32px

        @media $sxs
          width 100%
          height 348px

      &._small
        width 378px
        height 504px
        position absolute
        transform translateX(calc(100% - 98px))
        right 0
        top 87px
        @media $laptopMinus
          top 140px
          height 440px
        @media $mdMinus
          transform unset
          top 496px
          right 124px
          width 242px
          height 242px
        @media $smMinus
          right 24px
        @media $xs
          right 0
          top 460px
        @media $sxs
          display none

  &__freedom
    @media $xs
      padding-top 8px
    &-wrapper
      display flex
      position absolute
      gap 32px
      left 74%
      @media $lgMinus
        left 90%
        top unset
        transform unset
        bottom 0
        gap 60px

      @media $mdMinus
        right unset
        bottom unset
        gap 32px
        position unset
        display flex
        flex-direction column
        align-items center

    &-img
      position relative
      flex-shrink 0
      width 846px
      height 672px
      overflow hidden

      @media $laptopMinus
        height 508px

      @media $mdMinus
        width 100%
        height 448px

      @media $sxs
        height 238px

    &-text
      position relative
      flex-shrink 0
      z-index 3
      fontSzLh(32, 48)
      font-weight 300
      max-width 598px
      text-transform uppercase

      @media $laptopMinus
        fontSzLh(24, 38)

      @media $mdMinus
        padding 0 24px
        //margin-bottom 70px

      @media $sxs
        fontSzLh(20, 28)
        padding 0 12px
        margin-bottom 32px

  &__height
    min-width 100vw
    position relative
    z-index 2

    @media $lgMinus
      min-width 2280px

    @media $mdMinus
      min-width unset
      display flex
      flex-direction column
      align-items center

    &-img
      overflow hidden

      &._big
        position absolute
        right 124px
        width 606px
        height 645px
        margin-bottom 80px

        img
          top -16%

        @media $lgMinus
          height 560px
          margin-bottom 32px

        @media $laptopMinus
          height 457px

        @media $mdMinus
          inset auto
          position relative
          width 480px
          height 638px

        @media $sxs
          width 262px
          height 348px
          margin-bottom 107px

      &._small
        position absolute
        width 355px
        height 355px
        bottom 0
        right 50%
        transform translateX(50%)

        @media $laptopMinus
          width 320px
          height 320px
          transform translateX(100%)

        @media $mdMinus
          width 216px
          height 216px
          left unset
          right 124px
          bottom unset
          transform unset
          top -80px
        @media $smMinus
          right 24px
        @media $xs
          right 0
        @media $sxs
          width 150px
          height 150px
          top unset
          bottom 176px

    &-text
      position absolute
      bottom 80px
      left calc(100% + 64px)
      fontSzLh(32, 48)
      font-weight 300
      width 590px
      text-transform uppercase

      @media $laptopMinus
        fontSzLh(24, 38)

      @media $mdMinus
        position static
        bottom 64px
        width auto
        max-width 480px
        margin-bottom 102px
        text-align center

      @media $smMinus
        margin-bottom 48px

      @media $sxs
        fontSzLh(20, 28)
        // max-width 296px
        margin-bottom 32px
        padding 0 12px
        text-align left

  &__image
    position relative
    overflow hidden
    min-width 100vw

    @media $mdMinus
      min-width unset
      width 100%

    @media $xs
      height 430px

    @media $sxs
      height 210px

    &::after
      display block
      content ''
      position absolute
      left 0
      bottom 0
      width 100%
      height 400px
      background-image linear-gradient(180deg, rgba(0, 0, 0, 0) 0, #000000 100%)

      @media $mdMinus
        display none

    img
      width 100%
      height 100%
      object-fit cover

      @media $mdMinus
        max-width 100%
