@keyframes pulse-green
  0%
    box-shadow 0 0 0 0 rgba($green, .4)
  70%
    box-shadow 0 0 0 var(--pulse-width, 10px) rgba($green, 0)
  100%
    box-shadow 0 0 0 0 rgba($green, 0)

@keyframes pulse-white
  0%
    box-shadow 0 0 0 0 rgba($white, .4)
  70%
    box-shadow 0 0 0 var(--pulse-width, 10px) rgba($white, 0)
  100%
    box-shadow 0 0 0 0 rgba($white, 0)


@keyframes pulse-scale
  0%
    transform scale(1)
  30%
    transform scale(1.5)
  100%
    transform scale(1)

@keyframes headShake
  0%
    transform translateX(0)
  6.5%
    transform translateX(-6px) rotateY(-9deg)
  18.5%
    transform translateX(5px) rotateY(7deg)
  31.5%
    transform translateX(-3px) rotateY(-5deg)
  43.5%
    transform translateX(2px) rotateY(3deg)
  50%
    transform translateX(0)
