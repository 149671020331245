.Form__group
  position relative

  &._error
    .Form__field
      color $red
      border-bottom-color $red

    .Form__label
      color $red !important

    .Form__error
      display block

  .Form__error
    position absolute
    left 0
    top calc(100% + 1px)
    font-weight 300
    fontSzLh(12, 14)
    color $red
    margin-top 2px
    display none
    text-align left

  .Form__field
    width 100%
    border none
    outline none
    background transparent
    border-bottom 1px solid $green
    -webkit-appearance none
    border-radius 0
    fontSzLh(16, 32)
    color $green

    &:-webkit-autofill
      -webkit-box-shadow 0 0 0 1000px #f5f5f5 inset !important
      color #f5f5f5 !important

    &::placeholder
      color transparent

    &:placeholder-shown ~ .Form__label
      fontSzLh(16, 32)
      cursor text
      top 0
      left 0
      transition(all)
      color $green

    &:focus
      ~ .Form__label
        position absolute
        top -10px
        left 0
        display block
        fontSzLh(12, 14)
        font-weight 300
        transition(all)

  .Form__label
    position absolute
    top -10px
    left 0
    display block
    fontSzLh(12, 14)
    color $lightGreen
    transition(all)