.Plan
  background-image url('../img/plan-2.jpg')
  background-size cover
  background-position center
  background-repeat no-repeat
  height 100vh
  position relative
  @media $mdMinus
    height 852px
  @media $sxs
    height 428px
  &__option
    position absolute
    top 500px
    left 434px
    @media $mdMinus
      top 400px
      left 200px
    @media $sxs
      top 200px
      left 100px
    &.active
      .Plan__option-item
        box-shadow unset
        background unset
        border-radius unset
        transition(all)
        svg
          fill $green
          stroke $white
          box-shadow unset
          transition(all)
      .Plan__option-tooltip
        opacity 1
        transition(all)
    +hover()
      .Plan__option-item
        box-shadow unset
        background unset
        border-radius unset
        transition(all)
        svg
          fill $green
          stroke $white
          box-shadow unset
          transition(all)
      .Plan__option-tooltip
        opacity 1
        transition(all)
    &-item
      cursor pointer
      width 20px
      height 30px
      box-shadow 0px 0px 28px rgba(255, 255, 255, 0.7)
      background rgba($white, 0.2)
      border-radius 50%
      transition(all)
      @media $mdMinus
        width 16px
        height 24px
      svg
        width 100%
        height 100%
        fill $white
        transition(all)
    &-tooltip
      position absolute
      bottom 70px
      left 50%
      transform translateX(-50%)
      background $white
      padding 20px
      min-width 216px
      opacity 0
      pointer-events none
      transition(all)
      @media $mdMinus
        bottom 36px
        padding 24px 12px
      @media $sxs
        min-width 176px
      &:after
        content ""
        width 1px
        height 41px
        background $white
        position absolute
        left 50%
        transform translateX(-50%)
        bottom -41px
        @media $mdMinus
          display none
      &-title
        font-weight 300
        fontSzLh(20, 26)
        color $green
        margin-bottom 107px
        @media $mdMinus
          margin-bottom 8px
      &-text
        font-weight 400
        fontSzLh(14, 17)
        color $green
      &-close
        position absolute
        top 12px
        right 12px
        width 16px
        height 16px
        display none
        @media $mdMinus
          display block