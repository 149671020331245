.Agree
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  padding 0 !important
  overflow hidden !important
  background-color $green
  background-image url('../img/green-bg.png')
  background-size cover
  background-position center
  background-repeat no-repeat
  justify-content center
  align-items center
  z-index 3
  display none
  &.show
    display flex
  &__inner
    width 100%
    height 100%
    padding-top 172px
    padding-bottom 80px
    -ms-overflow-style none
    scrollbar-width none
    &::-webkit-scrollbar
      display none
    @media $laptopMinus
      padding-top 124px
      padding-left 40px
      padding-right 40px
    @media $sxs
      padding 44px 12px
      margin-right -12px
      padding-right 24px
  &__wrapper
    max-width 800px
    margin 0 auto
  &__close
    position absolute
    top 24px
    right 24px
    width 100px
    height 100px
    border 1px solid rgba($lightGreen, 0.5)
    border-radius 50%
    display inline-flex
    justify-content center
    align-items center
    cursor pointer
    z-index 2
    transition 2s
    -webkit-user-select none
    @media $sxs
      border none
      width 24px
      height 24px
      top 12px
      right 12px
    svg
      position absolute
      left -2px
      top -2px
      right 0
      bottom 0
      transform rotate(-90deg)
      @media $sxs
        display none
    +hover()
      .circle
        stroke-dashoffset 0
    .circle
      stroke-dasharray 301.59px 301.59px
      stroke-dashoffset 301.59px
      transition 2s
  &__title
    font-weight 100
    fontSzLh(56, 56)
    text-transform uppercase
    margin-bottom 40px
    @media $laptopMinus
      fontSzLh(40, 48)
    @media $sxs
      fontSzLh(24, 24)
      margin-bottom 16px
  &__text
    font-weight 400
    fontSzLh(20, 32)
    @media $sxs
      fontSzLh(16, 24)
  .simplebar-track.simplebar-vertical
    top 172px
    @media $sxs
      right 6px
    .simplebar-scrollbar:before
      background $white
      border-radius 4px
      width 4px
    .simplebar-scrollbar.simplebar-visible:before
      opacity 0.3