*
  word-wrap break-word
  word-break keep-all
  outline none
  box-sizing border-box
  margin 0
  padding 0

*:after, *:before
  box-sizing border-box

html
  min-width 320px
  height 100%
  position relative
  scroll-behavior smooth

body
  background-color $white
  background-image url('../img/bg.png')
  background-size contain
  background-position center
  background-repeat repeat
  height calc(var(--vh, 1vh) * 100)
  position relative
  cursor default
  -webkit-overflow-scrolling auto
  font 20px / 32px $font
  color $white

  @media $xs
    fontSzLh(16, 26)

  &._noScroll
    overflow hidden

section
  @media $mdMinus
    scroll-margin-top 48px
  @media $smMinus
    scroll-margin-top 38px

.Container
  max-width 1920px
  width calc(100% - 48px)
  margin 0 auto
  position relative

  @media $sxs
    width calc(100% - 24px)

input:not([type=checkbox]):not([type=radio]), textarea, select
  resize none
  appearance none

button
  background none
  border none
  outline none
  padding 0
  font-weight inherit
  font-size inherit
  line-height inherit
  color inherit
  cursor pointer

.Main
  overflow hidden

.fancybox
  &-active
    overflow hidden
  &-bg
    background transparent
  &-slide--html
    padding 0
