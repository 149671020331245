fontSzLh(size, lh)
  font-size unit(size, px)
  line-height unit(lh, px)

objectFit(size, fit)
  display block
  width size
  height size
  object-fit fit

transition(param)
  transition param $transition

hover()
  @media $hover
    &:hover
      {block}