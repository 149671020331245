.Button
  background $green
  border-radius 80px
  display flex
  justify-content center
  align-items center
  padding 24px 40px
  font-weight 400
  fontSzLh(20, 24)
  text-transform uppercase
  text-align center
  transition(all)
  +hover()
    opacity 0.8
    transition(all)
  &[disabled]
    pointer-events none
    opacity 0.8
  @media $laptopMinus
    border-radius 40px
    padding 12px 32px
    fontSzLh(16, 24)