.LocationMap
  width 100%
  height 100%
  padding 0
  position relative

  &__imgWrap
    height 100%
    overflow scroll

  .MapPlace
    &__popup
      position fixed
      z-index 3
      bottom 0
      left 0
      width 100%
      max-width 100%
      padding 38px 50px 38px 24px
      font-size 24px
      line-height 24px
      transform none
      transition none

      @media $sxs
        padding 28px 50px 28px 12px
        font-weight 300
        font-size 20px
        line-height 24px

    &._active
      .MapPlace__popup
        transform none

    &__popup-close
      display block

  &__imgDragger
    display inline-block
    white-space nowrap
    position relative
    width 138vmax
    height 150vmax
    background-image url('../img/place/map.svg')
    background-position center
    background-size auto 100%
    background-repeat no-repeat

  &__navigate
    pointer-events none
    position absolute
    left 0
    bottom 0
    right 0
    height 100px
    justify-content center
    align-items center
    background linear-gradient(180deg, rgba(1, 33, 29, 0) 0, #01211D 100%)
    padding 0 50px
    text-align center
    fontSzLh(16, 20)
    color $white
    display none
    z-index 5

    @media $mdMinus
      display flex

    @media $sxs
      height 80px

  &__close
    @media $mdMinus
      display flex
      justify-content center
      align-items center
      position absolute
      top 16px
      right 16px
      background $white
      border-radius 50%
      width 64px
      height 64px

      @media $sxs
        width 48px
        height 48px
        top 12px
        right 12px

      img
        width 20px
        height 20px
        object-fit contain

        @media $sxs
          width 16px
          height 16px

.LocationMap._place-opened
  .LocationMap
    &__navigate
      display none
