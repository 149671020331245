.About
  background-image url('../img/about-bg.png')
  background-size 70%
  background-position center 497px
  background-repeat no-repeat
  padding-top 120px
  padding-bottom 120px
  display flex
  flex-direction column

  @media $mdMinus
    padding-top 48px
    padding-bottom 180px
    background-size 170%
    background-position center top

  @media $sxs
    padding-top 24px
    padding-bottom 106px
    background-size 250%
    background-position center 72px

  &__title
    display flex
    flex-direction column

    &-part
      fontSzLh(120, 120)
      font-weight 100
      color $green
      text-transform uppercase

      @media $laptopMinus
        fontSzLh(100, 100)

      @media $mdMinus
        fontSzLh(56, 56)

      @media $sxs
        fontSzLh(40, 40)

      @media $mini
        fontSzLh(30, 32)

      &:nth-child(2), &:nth-child(3)
        align-self flex-end

      &:nth-child(2)
        @media $mdMinus
          align-self flex-start

  &__img-1
    width 346px
    height 346px
    margin-left 206px
    margin-top -100px
    margin-bottom 49px
    overflow hidden

    & img
      objectFit(100%, cover)

    @media $lgMinus
      margin-left 46px

    @media $laptopMinus
      margin-left 0

    @media $mdMinus
      width 224px
      height 224px
      margin-top 0
      margin-bottom -40px

    @media $sxs
      width 116px
      height 116px
      margin-left -12px
      margin-bottom -12px

  &__quot
    width 40px
    height 40px
    object-fit contain
    margin 0 auto
    margin-bottom 40px

    @media $sxs
      width 24px
      height 24px
      margin-bottom 16px

  &__text
    max-width 1100px
    margin 0 auto
    margin-bottom 48px
    fontSzLh(40, 56)
    font-weight 300
    color $green
    text-align center
    text-transform uppercase
    letter-spacing: 0.02em;

    @media $lgMinus
      max-width 1100px
      fontSzLh(48, 64)
      margin-bottom 56px

    @media $laptopMinus
      margin-bottom 48px

    @media $mdMinus
      fontSzLh(24, 38)
      margin-bottom 24px

      br
        display none

    @media $sxs
      fontSzLh(20, 28)
      margin-bottom 16px

  &__subtext
    max-width 484px
    margin 0 auto
    margin-bottom 64px
    fontSzLh(20, 32)
    font-weight 400
    color $green
    text-align center

    @media $mdMinus
      max-width 375px
      margin-bottom 48px
      fontSzLh(16, 24)

    @media $sxs
      max-width 220px
      margin-bottom 32px
      fontSzLh(14, 20)

  &__img-2
    width 356px
    height 356px
    margin 0 auto
    z-index 1
    overflow hidden

    @media $mdMinus
      width 240px
      height 240px

    @media $sxs
      width 128px
      height 128px

  &__imgs-wrapper
    margin-top -178px
    position relative
    display flex
    justify-content center

    @media $mdMinus
      margin-top -80px

    @media $sxs
      margin-top -55px

  &__img-3
    width 100%
    height 100%
    max-width 1114px
    max-height 1400px
    overflow hidden

    & img
      objectFit(100%, cover)

    @media $mdMinus
      height 960px

    @media $sxs
      max-width 262px
      max-height 348px

  &__img-4
    width 355px
    height 530px
    position absolute
    top 0
    right 0
    overflow hidden

    & img
      objectFit(100%, cover)

    @media $lgMinus
      right -24px
      top 184px

    @media $laptopMinus
      top 560px

    @media $mdMinus
      width 248px
      height 380px
      top unset
      bottom -100px

    @media $sxs
      right -12px
      width 132px
      height 202px
      bottom -66px