.Advantages
  --origin-x 230px
  --origin-y 2360px
  position relative
  @media $mdMinus
    padding-top 80px
    background url("../img/bg.png")
  @media $sxs
    padding-top 64px
  &__content
    @media $mdPlus
      width 100vw
      height 70vh
      max-height 710px
      overflow hidden
      max-width unset
      margin unset
      position fixed
      bottom 0
      z-index 20
      pointer-events none
      @media (max-height 850px)
        height 78vh
  &__gallery
    @media $mdPlus
      position absolute
      transform-origin var(--origin-x) calc(var(--origin-y) + 50px)
      left calc(50% - 5px) !important
      padding-top 50px
      opacity 0
  &__title
    display none
    width 100%
    text-align center
    font-weight 100
    color $green
    text-transform uppercase
    @media $mdMinus
      display inline-block
      margin-bottom 32px
      fontSzLh(56, 56)
    @media $sxs
      margin-bottom 24px
      fontSzLh(30, 32)
  &__navigations
    display none
    gap 8px
    margin-top 16px
    width 100%
    justify-content center
    @media $sxs
      display flex
  &__navigation
    width 28px
    height 28px
    & svg
      fill $green
      width 100%
      height 100%
    &._prev
      transform rotate(-180deg)
    &.swiper-button-disabled
      opacity 0.3

.Advantage-card
  display flex
  position relative
  flex-direction column
  width 460px
  min-height 494px
  align-items center
  padding 24px 46px 48px
  background url("../img/bg.png"), #fff
  color $green
  text-align center
  pointer-events none
  @media $mdPlus
    position absolute
    top 0
    left 0
    transform-origin: var(--origin-x) var(--origin-y)
    for n in (1..10)
      &:nth-of-type({n + 1})
        transform: rotate(n*15deg)
  @media $mdMinus
    height auto
    background #fff
  @media $sxs
    width 100%
    padding 24px 16px 32px
  &__title
    margin-bottom -0.5em
    fontSzLh(30, 32)
    font-weight 100
    z-index 1
    @media $sxs
      margin-bottom -1.2em
      fontSzLh(20, 28)
  &__index
    margin-bottom 4px
    fontSzLh(32, 40)
    text-transform uppercase
    font-weight 400
    color $bronze
    @media $sxs
      fontSzLh(24, 40)
  &__image
    mask-image linear-gradient(rgba(0,0,0,0), black 60%)
    width 162px
    height 174px
    @media $smMinus
      height 194px
    @media $sxs
      mask-image linear-gradient(rgba(0,0,0,0) 5%, black 30%)
    & img
      objectFit(100%, cover)
  &__text
    margin-top 16px
    fontSzLh(16, 24)
    @media $sxs
      fontSzLh(14, 20)
  &__link
    pointer-events all
    position absolute
    inset 0
    width 100%
    height 100%
