.Top
  overflow hidden
  min-height calc(var(--vh, 1vh) * 100)
  text-align center
  position relative
  z-index 1
  display flex
  flex-direction column
  justify-content center
  align-items center
  padding 100px 24px 0
  @media $smMinus
    padding-top 0
  &:before
    content ''
    position absolute
    top 0
    right 0
    bottom 0
    left 0
    background rgba(#000000, 0.5)
    z-index 1
  &__bg 
    display block
    objectFit(100%, cover)
    position absolute
    width 100%
    height 100%
    left 0
    top 0
    z-index -1
  &__logo
    position relative
    width 620px
    height auto
    margin-top auto
    margin-bottom 40px
    z-index 2
    @media (max-height 850px) and (orientation landscape)
      margin-top 0
    @media $smMinus
      width 100%
      margin-bottom 54px
    @media $xs
      margin-bottom 18px
    & svg
      width 100%
      height 100%

  &__title
    fontSzLh(64, 64)
    font-weight 100
    max-width 700px
    // margin-top: auto
    // margin-bottom auto
    text-transform uppercase
    position relative
    z-index 2
    @media $mdMinus
      fontSzLh(56, 56)
    @media $sxs
      max-width 250px
      fontSzLh(24, 24)
  &__text
    fontSzLh(24, 32)
    font-weight 400
    max-width 364px
    margin-top auto
    margin-bottom 48px
    text-transform uppercase
    position relative
    z-index 2
    @media $mdMinus
      fontSzLh(20, 28)
      margin-bottom 40px
    @media $sxs
      fontSzLh(16, 24)
      margin-bottom 24px
      max-width 240px
