.Logo
  display flex
  justify-content center
  align-items center
  pointer-events none
  position absolute
  z-index 2
  top 0
  left 0
  width 100%
  height 100%
  overflow hidden

  @media $mdMinus
    display none

  &__img
    // position absolute
    // top 50%
    // left 50%
    width 100%
    // transform translateX(-50%) translateY(-50%)