.Fitblock
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    overflow hidden
    user-select none
    &__inner
        position absolute
        top 50%
        left 50%
        visibility hidden
        overflow hidden
    &__pusher
        display block
        pointer-events none
        max-width unset
    &._scrollable
        @media screen and (max-width: 1252px)
            margin-left 0 !important
            left 0
            overflow-x scroll
    &._scrollable > &_inner
        @media screen and (max-width: 1252px)
            margin-left 0 !important
            left 0
