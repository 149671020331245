.Genplan
  --pulse-width 30px
  position relative
  width 100%
  background url("../img/bg.png")
  @media $sxs
    --pulse-width 20px
  &__inner
    height 100%
  &__title,
  &__subtitle
    position absolute
    width 100%
    text-align center
    z-index 2
    text-transform uppercase
  &__backdrop
    opacity 0
    pointer-events none
    position fixed
    inset 0
    width 100vw
    height calc(var(--vh, 1) * 100)
    background rgba(#02221E, .8)
    z-index 10
    @media (min-width: 768px)
      display none
  &__subtitle
    top 120px
    fontSzLh(40, 56)
    @media $mdMinus
      top 80px
      fontSzLh(24, 38)
    @media $sxs
      top 48px
      fontSzLh(20, 28)
  &__title
    top 256px
    fontSzLh(120, 120)
    font-weight 100
    @media $laptopMinus
      top 240px
      fontSzLh(100, 100)
    @media $mdMinus
      top 142px
      fontSzLh(56, 56)
    @media $sxs
      top 92px
      fontSzLh(30, 32)
  &__image
    position relative
    top 0
    left 0
    background-repeat no-repeat
    &._panorama
      height 1500px
      aspect-ratio 1.2825
      background-image url('../img/plan-large.jpg')
      background-size cover
      background-position left top
      @media $lgPlus
        height initial
        width 100%
      @media (max-width: 1279px) and (min-width: 1025px)
        height 1000px
      @media (max-width: 1024px) and (min-width: 640px)
        height 800px
        background-image url('../img/plan-large.jpg')
      @media $sxs
        height 640px
        left -50px
        background-image url('../img/plan-large.jpg')

    &._architecture
      position absolute
      left 0
      top 50%
      width 100%
      background-size 100% auto
      background-position center
      background-repeat no-repeat
      transform translateY(-50%)

      &::after
        padding-top 56.25%

      @media (min-width 1280px) and (max-aspect-ratio 1 / .55)
        width 177vh
        height 100%
        top 0
        left 50%
        background-image url('../img/plan-2.jpg')
        transform translateX(-50%)

        &::after
          padding 0

      @media $mdMinus
        position relative
        top 0
        background-image url('../img/plan-2-tablet.png')
        background-position left top
        transform none

        &::after
          padding-top 78.33%

      @media $xs
        left calc(100vw - 766px)
        width 766px
        height 600px

      @media $sxs
        left calc(100vw - 639px)
        width 639px
        height 500px

      @media (max-width 535px)
        left 50vw
        width 535px
        height 419px
        transform translateX(-50%)

      @media (max-width 470px)
        transform translateX(-55%)

      @media $mini
        left calc(100vw - 454px)
        width 454px
        height 356px
        background-image url('../img/plan-2-mobile.png')
        transform none

      @media (max-width 380px)
        left calc(100vw - 425px)

  &__option[data-horizontal-position=moved-right]
    .Genplan__option-tooltip
      @media $xsPlus
        left -20px
        transform none
  &__option[data-horizontal-position=moved-left]
    .Genplan__option-tooltip
      @media $xsPlus
        transform translateX(-100%)
  &__option[data-vertical-position=moved-down]
    .Genplan__option-tooltip
      @media $xsPlus
        bottom auto
        top calc(100% + 40px)

  &__option
    position absolute
    z-index 1
    &:nth-of-type(2)
      & .Genplan__option-tooltip-image img
        object-position center bottom
    &._active
      z-index 11
      .Genplan__option
        &-item
          &::before
            opacity 0
          @media $sxs
            & svg
              fill #36504d
        &-tooltip
          pointer-events auto
          opacity 1
          &::before
            content ''
            position fixed
            inset 0

    &-item
      position relative
      cursor pointer
      width 34px
      height 34px
      filter drop-shadow(0px 0px 28px rgba(255, 255, 255, 0.70));
      transform translateZ(0)
      will-change transform
      & svg
        fill $white
      &::before
        content ''
        position absolute
        transform translate(7px, 7px)
        width 20px
        height 20px
        border-radius 50%
        animation pulse-white 2s infinite
      @media $mdMinus
        transform translate(-50%, -50%)

    &-tooltip
      display flex
      position absolute
      bottom calc(50% - 190px)
      left 50%
      background $green
      width 802px
      height 380px
      opacity 0
      pointer-events none
      transition(opacity)
      z-index -1
      @media $laptopMinus
        width 644px
        height 362px
        bottom calc(50% - 180px)
      @media $mdMinus
        position fixed
        width 100vw
        height fit-content
        bottom 0
        left 0
        z-index 2
      @media $sxs
        flex-direction column

      &-image
        flex-shrink 0
        width 290px
        height auto
        & img
          objectFit(100%, cover)
        @media $laptopMinus
          width 328px
        @media $mdMinus
          width 244px
        @media $sxs
          object-position bottom
          width 100vw
          height 200px

      &-title
        font-weight 300
        fontSzLh(20, 26)
        color $white
        @media $sxs
          font-weight 100
          fontSzLh(30, 32)

      &-text
        margin-top 24px
        font-weight 400
        fontSzLh(20, 32)
        color $white
        @media $laptopMinus
          fontSzLh(16, 24)
          margin-top 16px

      &-content
        display flex
        width 100%
        flex-direction column
        justify-content space-between
        padding 50px 32px 40px 30px
        @media $mdMinus
          justify-content flex-start
          padding 40px 24px 52px
        @media $sxs
          padding 40px 12px 16px

      &-close
        position absolute
        top 16px
        right 16px
        width 24px
        height 24px
        display none
        curson pointer
        pointer-events all
        @media $mdMinus
          display block
          top 16px
          right 16px

  &__text
    position absolute
    right 24px
    bottom 88px
    fontSzLh(24, 34)
    font-weight 400
    max-width 566px
    pointer-events none
    @media $lgMinus
      bottom 92px
    @media $laptopMinus
      bottom 40px
      fontSzLh(20, 32)
    @media $mdMinus
      max-width 411px
      left calc(50% - 205px)
      text-align center
      bottom 32px
      fontSzLh(16, 24)
    @media $sxs
      position relative
      inset 0
      width auto
      max-width 100%
      padding 24px 12px 0
      color $green
      text-align initial
  &._place-opened
    .Genplan__backdrop
      pointer-events all
      opacity 1
