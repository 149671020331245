.Place
  position relative
  background url(../img/bg.png)

  &__content
    display flex
    justify-content flex-end

    @media $laptopMinus
      display block

  &__text
    display flex
    flex-direction column
    justify-content center
    position absolute
    left 0
    width calc(100% - 1030px)
    height 100%
    padding-top 35px
    color $green

    @media $lgMinus
      justify-content flex-start
      width 600px
      padding-top 206px

    @media $laptopMinus
      position static
      width 100%
      padding-top 80px
      text-align center

    @media $mdMinus
      padding-bottom 80px

    @media $sxs
      padding 64px 0
      text-align left

  &__title
    fontSzLh(120, 120)
    font-weight 50
    text-transform uppercase

    @media $mdMinus
      fontSzLh(56, 56)

    @media $sxs
      fontSzLh(30, 32)

  &__title-part
    display block

  &__description
    width 355px
    margin 80px 0 0 379px
    fontSzLh(20, 32)

    @media $lgMinus
      width 445px
      margin 40px 0 0

    @media $laptopMinus
      width 730px
      margin 40px auto 0

    @media $mdMinus
      width 472px
      margin 32px auto 0
      fontSzLh(16, 24)

    @media $sxs
      width 100%
      margin-top 24px

  &__map
    position relative
    width 1280px
    margin-right -24px
    margin-left auto

    @media $laptopMinus
      margin-right 0
      margin-left -24px
      margin-top 24px

    @media $mdMinus
      display none

    img
      display block
      width 100%

  &__mobile-map-btn
    display none
    margin 48px auto 0
    border-radius 40px
    padding 12px 24px
    fontSzLh(20, 24)
    font-weight 400
    color $green
    text-transform uppercase
    background-color #DDE8E6

    @media $mdMinus
      display block

    @media $sxs
      margin-top 24px
      fontSzLh(16, 24)
