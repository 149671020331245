.Slider
  padding-bottom 120px
  position relative

  @media $mdMinus
    padding-bottom 80px

  @media $sxs
    padding-bottom 40px

  &__slide
    width 100%
    max-width 1470px
    margin 0 40px

    @media $lgMinus
      max-width 100%
      display flex
      flex-direction column
      align-items center
      justify-content center

    @media $mdMinus
      padding 0 24px
      margin 0 24px

    @media $sxs
      padding 0 12px
      margin 0 12px

  &__img
    width 100%
    max-width 1470px
    height 920px
    object-fit cover
    margin-bottom 16px

    @media $laptopMinus
      max-width 1148px
      height 718px

    @media $mdMinus
      max-width 100%
      height 377px
      margin-bottom 11px
      object-fit unset

    @media $sxs
      height 188px
      margin-bottom 8px

  &__text
    fontSzLh(24, 34)
    font-weight 400
    text-transform uppercase
    text-align center
    color $green

    @media $mdMinus
      fontSzLh(16, 24)

    @media $sxs
      max-width 200px

  &__arrows
    display flex
    flex-direction column

    .SliderArrows__arrow
      width 160px
      height 160px
      background $green
      border-radius 50%
      display inline-flex
      justify-content center
      align-items center
      cursor pointer
      z-index 2
      transition(all)
      position absolute
      top 380px

      +hover()
        background #337870
        transition(all)

      @media $laptopMinus
        top 279px

      @media $mdMinus
        width 33px
        height 14px
        background none
        top -40px

      @media $sxs
        width 28px
        height 12px
        top -30px

      &-icon
        width 48px
        height 15px
        display flex
        justify-content center
        align-items center

        @media $mdMinus
          width 33px
          height 14px

        @media $sxs
          width 28px
          height 12px

        svg
          width 48px
          height 15px
          fill $white

          @media $mdMinus
            width 33px
            height 14px
            fill $green

          @media $sxs
            width 28px
            height 12px

      &._prev
        left 184px

        .SliderArrows__arrow-icon
          transform rotate(180deg)

        @media $lgMinus
          left 24px

        @media $mdMinus
          left unset
          right 74px

        @media $sxs
          right 52px

      &._next
        right 184px

        @media $lgMinus
          right 24px

        @media $sxs
          right 12px