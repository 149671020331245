.Checkbox
  cursor pointer
  position relative

  &._error
    .Checkbox__label
      color $red
      animation-duration 1s
      animation-name headShake
      animation-timing-function ease-in-out

      &:before
        border-color $red

      a
        color inherit

    +hover()
      .Checkbox__label:before
        border-color $red

  +hover()
    .Checkbox__label:before
      border-color $green

  .Checkbox__input
    position absolute
    left 0
    top 0
    width 1px
    height 1px
    opacity 0
    cursor pointer

    &:checked~.Checkbox__label:after
      content ''
      position absolute
      left 3px
      top 6px
      width 7px
      height 7px
      background $green

  .Checkbox__label
    display block
    fontSzLh(14, 20)
    font-weight 400
    text-align left
    padding-left 20px
    position relative
    cursor pointer
    color $green
    transition(all)

    &:before
      content ''
      position absolute
      left 0
      top 3px
      width 13px
      height 13px
      border 1px solid $lightGreen
      transition(all)

    & a
      border-bottom 1px solid $dark
      transition(all)
      color $lightGreen

      +hover()
        color $green
        transition(all)