@font-face
  font-family 'Apoc'
  src url("../fonts/Apoc-Hairline-Web.woff2") format('woff2'), url("../fonts/Apoc-Hairline-Web.woff") format('woff')
  font-weight 100
  font-style normal

@font-face
  font-family 'Apoc'
  src url("../fonts/Apoc-Light-Web.woff2") format('woff2'), url("../fonts/Apoc-Light-Web.woff") format('woff')
  font-weight 300
  font-style normal

@font-face
  font-family 'Apoc'
  src url("../fonts/Apoc-Regular-Web.woff2") format('woff2'), url("../fonts/Apoc-Regular-Web.woff") format('woff')
  font-weight 400
  font-style normal