.hidden-xs
	@media $xs
		display none!important
.hidden-sm
	@media $sm
		display none!important
.hidden-md
	@media $md
		display none!important

.hidden
	display none!important