.Location-wrap
  position relative
  width 100%

section.Location__time-wrap
  width 590px
  height 100%
  flex-shrink 0
  position relative
  z-index 5 !important

  @media $laptopMinus
    width 400px

  @media $mdMinus
    width 100%
    height 68px

.Location
  overflow hidden
  height 100vh

  @media $isTouch
    height auto
    overflow auto

  &__bg
    position absolute
    width 100vw !important
    max-width none
    height 100%
    left 0
    top 0
    z-index 1
    objectFit(100%, cover)

  &__wrap
    display flex
    max-height 100%
    // overflow-x auto
    // overflow-y hidden
    // -ms-overflow-style none
    // overflow hidden
    position relative
    z-index 1

    @media $mdMinus
      flex-direction column
      position static

    & section
      position relative
      z-index 1
      max-height 100%

      &.Location__map
        @media $mdMinus
          position fixed
          z-index 500
          overflow scroll

          & img
            max-width none
            width auto
            max-height none
            height 100%

  &__parallax-img
    display block
    position absolute
    top 0
    left -30%
    min-width 130%

    @media $mdMinus
      position static
      left 0
      min-width 0
      max-width 100%

  &__place
    width 100%
    min-width 100vw
    padding-top 120px
    padding-bottom 80px
    display flex
    justify-content space-between
    position relative
    height 100vh

    @media $lgMinus
      padding-bottom 112px

    @media $mdMinus
      padding-top 80px
      padding-bottom 80px
      display flex
      flex-direction column
      height unset

    @media $sxs
      padding-top 40px
      padding-bottom 40px

    &-texts-wrapper
      display flex
      flex-direction column
      margin-right 130px

      @media $lgMinus
        margin-right -30px

      @media $laptopMinus
        margin-right 0

    &-title
      fontSzLh(120, 120)
      font-weight 100
      text-transform uppercase
      margin-top 80px
      margin-bottom 80px
      margin-left 24px
      max-width 1010px
      position relative
      z-index 2

      @media $laptopMinus
        fontSzLh(100, 100)
        margin-bottom 41px
        margin-top 10px

      @media $mdMinus
        fontSzLh(56, 56)
        margin-bottom 32px
        margin-top 0
        max-width 490px

      @media $sxs
        fontSzLh(30, 32)
        margin-bottom 16px
        margin-left 12px

    &-text
      fontSzLh(20, 32)
      font-weight 400
      margin-left 403px
      max-width 475px

      @media $lgMinus
        margin-left 243px

      @media $mdMinus
        fontSzLh(16, 24)
        max-width 460px
        margin-bottom 16px
        margin-left 24px

      @media $sxs
        margin-bottom 8px
        margin-left 12px

    &-img-wrapper
      display flex
      flex-direction column
      position relative
      margin-right 151px

      @media $lgMinus
        margin-right 0

      @media $mdMinus
        display none

    &-img
      position relative
      width 607px
      min-width 607px
      height 832px
      margin-bottom 16px
      overflow hidden

      & img
        objectFit(100%, cover)

      @media $lgMinus
        width 606px
        min-width 606px
        height 620px

      @media $laptopMinus
        width 414px
        min-width 414px
        height 440px

    &-span
      fontSzLh(20, 32)
      font-weight 400
      text-transform uppercase

      @media $sxs
        fontSzLh(16, 24)

    &-next
      width 300px
      height 300px
      border 1px solid $white
      border-radius 50%
      display flex
      justify-content center
      align-items center
      position absolute
      right 64px
      bottom 270px

      @media $lgMinus
        bottom 90px
        right 24px

      @media $laptopMinus
        bottom 300px

      @media $mdMinus
        display none

      &-icon
        width 48px
        height 20px

    &-arrows
      display none
      justify-content flex-end
      align-items center
      z-index 3
      padding 0 24px
      margin-bottom 24px

      @media $mdMinus
        display flex

      @media $sxs
        padding 0 12px
        margin-top 12px
        margin-bottom 20px

    &-arrow
      display flex
      justify-content center
      align-items center
      width 33px
      height 14px

      &.swiper-button-disabled
        opacity .5

      @media $sxs
        width 28px
        height 12px

      svg
        fill $white
        width 33px
        height 14px

        @media $sxs
          width 28px
          height 12px

      &._prev
        margin-right 16px
        transform rotate(180deg)

    &-slider
      display none
      overflow hidden
      // gap 24px
      // overflow-x auto
      // overflow-y hidden
      padding-left 24px
      padding-right 24px
      scrollbar-width none
      -ms-overflow-style none
      position relative

      @media $mdMinus
        display flex

      @media $sxs
        gap 12px
        padding-left 12px
        padding-right 12px

      &::-webkit-scrollbar
        display none

      &-item
        display flex
        flex-direction column

        &._item-1
          min-width 392px
          max-width 392px

          @media $sxs
            min-width 288px
            max-width 288px

          img
            height 480px

            @media $sxs
              height 426px

        &._item-2
          min-width 284px
          max-width 284px

          @media $sxs
            min-width 288px
            max-width 288px

          img
            height 392px

            @media $sxs
              height 426px

        &._item-3
          min-width 264px
          max-width 264px

          @media $sxs
            min-width 288px
            max-width 288px

          img
            height 442px

            @media $sxs
              height 426px

      &-img
        position relative
        width 100%
        margin-bottom 16px
        overflow hidden

        & img
          objectFit(100%, cover)

        @media $sxs
          margin-bottom 8px

      &-span
        fontSzLh(16, 24)
        font-weight 400
        text-transform uppercase

  &__genius
    // min-width 120vw
    min-width 2240px
    height 100vh
    display flex
    padding-top 140px
    padding-bottom 80px
    padding-right 170px
    position relative

    @media $lgMinus
      padding-top 124px
      padding-bottom 74px
      margin-left 24px
      min-width 130vw

    @media $laptopMinus
      padding-top 99px
      min-width 123vw

    @media $mdMinus
      height unset
      padding-top 0
      padding-bottom 80px
      padding-left 24px
      padding-right 24px
      width 100%
      min-width unset
      margin-left 0
      // display flex
      display none
      flex-direction column
      justify-content center
      align-items center
      text-align center

    @media $sxs
      padding-bottom 40px
      text-align left
      padding-left 12px
      padding-right 12px

    &-btn
      background rgba($white, .2)
      border-radius 40px
      padding 12px 24px
      fontSzLh(20, 24)
      font-weight 400
      text-transform uppercase
      display none

      @media $mdMinus
        display block

      @media $sxs
        fontSzLh(16, 24)

    &-gradient
      position absolute
      right 0
      top 0
      bottom 0
      min-width 270px
      background-image url('../img/location-gradient.png')
      background-size cover
      background-position center
      background-repeat no-repeat

      @media $mdMinus
        display none

    &-texts-wrapper
      display flex
      flex-direction column
      min-width 840px
      margin-right auto
      margin-top 380px
      padding-left 32px

      @media $laptopMinus
        margin-top 30px

      @media $mdMinus
        margin-top 0
        margin-bottom 48px

      @media $sxs
        margin-bottom 24px

    &-title
      fontSzLh(120, 120)
      font-weight 100
      text-transform uppercase
      white-space nowrap
      z-index 2

      @media $laptopMinus
        fontSzLh(100, 100)

      @media $mdMinus
        fontSzLh(56, 56)

      @media $sxs
        fontSzLh(30, 32)

      &:nth-child(2)
        margin-left 248px
        margin-bottom 80px

        @media $laptopMinus
          margin-bottom 32px

        @media $mdMinus
          margin-left 0

        @media $sxs
          margin-bottom 16px

    &-text
      fontSzLh(20, 32)
      font-weight 400
      max-width 535px

      @media $mdMinus
        fontSzLh(16, 24)

    &-img-wrapper
      display flex
      flex-direction column
      position relative
      z-index 1

      @media $mdMinus
        display none

      &._wrapper-1
        margin-right 80px
        margin-left -170px

        @media $laptopMinus
          margin-left -45px
          margin-right 40px

      &._wrapper-2
        .Location__genius-span
          @media $laptopMinus
            max-width 275px

    &-img
      position relative
      margin-bottom 16px
      overflow hidden

      & img
        objectFit(100%, cover)

      &._genius-img-1
        width 740px
        min-width 740px
        height 812px

        @media $lgMinus
          height 654px

        @media $laptopMinus
          width 439px
          min-width 439px
          height 480px

      &._genius-img-2
        width 408px
        min-width 408px
        height 530px

        @media $laptopMinus
          width 275px
          min-width 275px
          height 389px

    &-span
      fontSzLh(20, 32)
      font-weight 400
      text-transform uppercase

  &__map
    position relative
    flex 0 0 auto
    // min-width 994px
    // min-height calc(var(--vh, 1vh) * 100)
    // position relative
    text-align right

    &.show
      display block

    @media $lgMinus
      // min-width 828px

    @media $mdMinus
      min-width unset
      position fixed
      top 0
      right 0
      bottom 0
      left 0
      z-index 9
      display none

    &-frame
      width auto
      max-width none
      height 100vh
      min-width 92.1vh
      min-height 100%

    &-close
      display none

      @media $mdMinus
        display flex
        justify-content center
        align-items center
        position absolute
        top 16px
        right 16px
        background $white
        border-radius 50%
        width 64px
        height 64px

        @media $sxs
          width 48px
          height 48px
          top 12px
          right 12px

        img
          width 20px
          height 20px
          object-fit contain

          @media $sxs
            width 16px
            height 16px

    &-navigate
      position absolute
      left 0
      bottom 0
      right 0
      height 100px
      justify-content center
      align-items center
      background linear-gradient(180deg, rgba(1, 33, 29, 0) 0, #01211D 100%)
      padding 0 50px
      text-align center
      fontSzLh(16, 20)
      color $white
      display none
      z-index 2

      @media $mdMinus
        display flex

      @media $sxs
        height 80px

    &-popup
      position absolute
      left 114px
      top 153px
      background $white
      fontSzLh(20, 26)
      font-weight 300
      color $green
      padding 20px
      max-width 216px

      &.hide
        display none

      @media $mdMinus
        left 0
        bottom 0
        right 0
        top unset
        height 100px
        max-width 100%
        padding 0 24px
        display flex
        justify-content space-between
        align-items center
        background-image url('../img/bg.png')
        background-size cover
        background-position center
        background-repeat no-repeat
        z-index 3

      @media $sxs
        height 80px
        padding 0 12px

      &-close
        width 20px
        height 20px
        justify-content center
        align-items center
        display none

        @media $mdMinus
          display flex

        @media $sxs
          width 16px
          height 16px

  &__history
    display flex
    flex-direction column
    min-width 1620px
    // background-image url('../img/location-img-4.png')
    // background-size cover
    // background-position center
    // background-repeat no-repeat
    padding-top 200px

    @media $lgMinus
      padding-top 164px

    @media $laptopMinus
      padding-top 140px
      min-width 1280px

    @media $mdMinus
      padding-top 80px
      min-width unset
      min-height 725px

    @media $sxs
      padding-top 40px
      min-height 400px

      &:not(._mobile)
        .Location__history-title, .Location__history-text
          display none

    &-bg
      position absolute
      width 100%
      height 100%
      left 0
      top 0
      overflow hidden
      z-index -1

      & img
        objectFit(100%, cover)

    &._mobile
      display none

      @media $sxs
        display block
        background-image unset
        min-height unset

    &-title
      fontSzLh(120, 144)
      font-weight 100
      margin-left 80px
      position relative
      z-index 1

      @media $laptopMinus
        fontSzLh(100, 100)

      @media $mdMinus
        fontSzLh(56, 56)
        margin-left 24px
        margin-bottom 32px
        padding-right 60px

      @media $sxs
        fontSzLh(30, 32)
        margin-bottom 16px
        margin-left 12px

    &-text
      fontSzLh(24, 34)
      font-weight 400
      margin-left auto
      margin-right 96px
      margin-top auto
      margin-bottom 92px
      max-width 591px
      position relative
      z-index 1

      @media $lgMinus
        margin-top -85px

      @media $laptopMinus
        fontSzLh(20, 32)
        margin-top 0
        margin-right 80px

      @media $mdMinus
        fontSzLh(16, 24)
        margin-left 24px
        margin-right 0
        max-width 472px

      @media $sxs
        margin-left 12px
        padding-right 12px

  &__time
    background-color $white
    background-image url('../img/bg.png')
    background-size cover
    background-position center
    background-repeat no-repeat
    width 590px
    height 100vh
    display flex
    flex-direction column
    justify-content space-between
    padding 120px 56px 210px
    position relative
    z-index 5

    @media $lgMinus
      padding 80px 80px 120px 56px

    @media $laptopMinus
      padding 80px 24px 100px
      width 400px

    @media $mdMinus
      padding 0 24px
      width unset
      height 100px
      flex-direction row-reverse
      align-items center

    @media $sxs
      height 68px
      padding 0 12px

    &-wrapper
      margin-left auto

      @media $mdMinus
        margin-left unset
        display flex
        align-items center

    &-count
      fontSzLh(200, 240)
      font-weight 100
      color $green
      text-align center

      @media $mdMinus
        fontSzLh(20, 32)
        margin-right 4px

      @media $sxs
        fontSzLh(40, 48)
        margin-right 8px

    &-text
      fontSzLh(32, 40)
      font-weight 400
      color $green
      text-align center

      @media $mdMinus
        fontSzLh(20, 32)

      @media $sxs
        fontSzLh(14, 14)
        max-width 50px
        text-align left

    &-place
      fontSzLh(64, 77)
      font-weight 300
      color $green

      @media $lgMinus
        fontSzLh(50, 64)

      @media $laptopMinus
        fontSzLh(40, 56)

      @media $mdMinus
        fontSzLh(32, 48)

      @media $sxs
        fontSzLh(20, 24)
        margin-top 12px

  &__gallery
    padding 140px 120px 0
    min-width 120vw
    display flex

    @media $lgMinus
      padding 124px 120px 0 80px
      min-width 145vw

    @media $laptopMinus
      padding 0 120px 0 80px
      min-width 160vw

    @media $mdMinus
      padding 60px 0 80px
      flex-direction column
      align-items center
      text-align center
      min-width unset

    @media $sxs
      padding 32px 0 40px

    &-wrapper
      margin-right 80px

      @media $lgMinus
        margin-right 124px

      @media $laptopMinus
        margin-top 100px
        margin-right 80px

      @media $mdMinus
        display flex
        flex-direction column
        margin-top 0
        margin-right 0
        padding 0 24px
        margin-bottom 48px

      @media $sxs
        padding 0
        margin-bottom 32px

    &-text
      fontSzLh(20, 32)
      font-weight 400

      @media $mdMinus
        fontSzLh(16, 24)

    &-text-1
      @media $mdMinus
        text-align left

      @media $sxs
        padding-left 12px

    &-text-2
      display none
      margin-top 16px
      text-align center

      @media $mdMinus
        display block

      @media $sxs
        margin-top 8px

    &-text-3
      display block
      margin-top 40px
      padding-right 81px
      text-align right

      @media $laptopMinus
        margin-top 16px

      @media $mdMinus
        display none

    &-img
      position relative
      overflow hidden

      & img
        objectFit(100%, cover)

    &-img-1
      width 998px
      min-width 998px
      height 678px
      margin-bottom 16px

      @media $lgMinus
        width 996px
        min-width 996px
        height 656px

      @media $laptopMinus
        width 781px
        min-width 781px
        height 464px

      @media $mdMinus
        width 100%
        min-width unset
        height 540px

      @media $sxs
        height 210px

    &-wrapper-2
      margin-top 140px

      @media $lgMinus
        margin-top auto

      @media $mdMinus
        order 3
        margin-top -150px

      @media $sxs
        margin-top -115px

    &-img-2
      width 604px
      min-width 604px
      height 800px

      @media $lgMinus
        height 640px

      @media $laptopMinus
        height 509px

      @media $mdMinus
        width 444px
        min-width unset
        height 485px

      @media $sxs
        width 262px
        height 348px

    &-wrapper-3
      position relative
      margin-left -150px

      @media $mdMinus
        order 2
        margin-left auto
        z-index 1

    &-img-3
      width 540px
      min-width 540px
      height 360px

      @media $mdMinus
        width 360px
        min-width unset
        height 240px

      @media $sxs
        width 130px
        height 164px

  &__art
    z-index 2 !important
    display flex
    min-width 130vw

    @media $lgMinus
      min-width 155vw

    @media $laptopMinus
      min-width 174vw

    @media $mdMinus
      min-width unset
      flex-direction column
      align-items center
      padding-bottom 80px

    @media $sxs
      padding-bottom 40px
      position relative

    &-img
      position relative
      z-index 2
      overflow hidden

      & img
        objectFit(100%, cover)

    &-img-1
      min-width 803px
      max-height 100vh

      @media $lgMinus
        min-width 802px

      @media $laptopMinus
        min-width 639px

      @media $mdMinus
        min-width unset
        width 520px
        height 699px
        margin-top -350px
        order 3

      @media $sxs
        width 262px
        height 348px
        margin-top 0

    &-img-2
      z-index 3
      min-width 503px
      height 799px
      margin-top 140px
      margin-left -80px
      margin-right 24px

      @media $lgMinus
        margin-top 0

      @media $laptopMinus
        min-width 340px
        height 580px
        margin-left 80px

      @media $mdMinus
        width 248px
        min-width unset
        height 395px
        margin-left auto
        margin-right 0
        order 2

      @media $sxs
        width 128px
        height 202px
        position absolute
        right 0
        bottom 233px

    &-img-3
      z-index 3
      min-width 382px
      height 488px
      margin-top 140px

      @media $lgMinus
        margin-top 99px

      @media $laptopMinus
        min-width 228px
        height 292px
        margin-top 100px

      @media $mdMinus
        width 308px
        min-width unset
        height 393px
        margin-top -280px
        margin-right auto
        order 4

      @media $sxs
        width 108px
        height 140px
        margin-top 0
        position absolute
        left 0
        bottom 139px

    &-wrapper
      margin-top 200px
      // margin-left -260px
      padding-left 80px
      z-index 4

      @media $lgMinus
        margin-top 164px

      @media $laptopMinus
        margin-top 140px
        margin-left -200px

      @media $mdMinus
        margin-top 0
        margin-left 0
        padding-left 0
        order 1

    &-title
      fontSzLh(120, 120)
      font-weight 100
      text-transform uppercase
      white-space nowrap

      @media $laptopMinus
        fontSzLh(100, 100)

      @media $mdMinus
        fontSzLh(56, 56)
        text-align center

      @media $sxs
        fontSzLh(30, 32)
        text-align left
        padding 0 12px

      span
        display block

        &:nth-child(1)
          margin-left -337px

          @media $mdMinus
            margin-left 0

        &:nth-child(2)
          margin-bottom 80px

          @media $laptopMinus
            margin-bottom 40px

          @media $mdMinus
            margin-bottom 32px

          @media $sxs
            margin-bottom 16px

    &-text
      fontSzLh(20, 32)
      font-weight 400
      max-width 592px

      // margin-left 340px
      @media $laptopMinus
        fontSzLh(20, 32)
        max-width 656px
        // margin-left 280px

      @media $mdMinus
        max-width 472px
        fontSzLh(16, 24)
        margin-left 0
        text-align center
        margin-bottom 48px

      @media $sxs
        margin-bottom 80px
        text-align left
        padding 0 12px

  &__image
    min-width 1600px
    margin-left -220px
    overflow hidden
    position relative

    & img
      objectFit(100%, cover)
      position absolute
      // width 100%
      // height 100%
      z-index 1
      // left 0

    @media $mdMinus
      min-width unset
      width 100%
      height 493px
      margin-left 0

    @media $sxs
      height 248px

  &__style
    display flex
    align-items flex-end
    position relative
    min-width 147vw

    @media $lgMinus
      min-width 180vw

    @media $laptopMinus
      min-width 220vw

    @media $mdMinus
      min-width unset
      flex-direction column
      align-items unset
      padding-top 80px
      padding-bottom 80px

    @media $sxs
      padding-top 32px
      padding-bottom 64px

    &-texts-wrapper
      display flex
      flex-direction column
      position absolute
      top 200px
      left 120px
      z-index 1

      @media $lgMinus
        top 164px

      @media $laptopMinus
        top 160px
        left 342px

      @media $mdMinus
        width 100%
        position unset
        top unset
        left unset
        flex-direction row
        flex-wrap wrap
        max-width 600px
        padding-left 24px
        margin-bottom 24px

      @media $sxs
        padding-left 12px
        padding-right 12px

    &-title
      fontSzLh(120, 120)
      font-weight 100
      text-transform uppercase

      @media $laptopMinus
        fontSzLh(100, 100)

      @media $mdMinus
        fontSzLh(56, 56)

      @media $sxs
        fontSzLh(30, 32)
        text-align center
        width 100%

    &-title-2
      @media $mdMinus
        margin-right 8px

      @media $sxs
        margin-right 0
        text-align center

    &-title-3
      // margin-left 482px
      margin-left 182px

      @media $lgMinus
        // margin-left 491px
        margin-left 191px

      @media $laptopMinus
        margin-left 235px

      @media $mdMinus
        margin-left 0

      @media $sxs
        text-align center

    &-span
      position relative
      z-index 2
      fontSzLh(20, 32)
      font-weight 400
      text-transform uppercase

    &-img-wrapper
      display flex
      flex-direction column
      position relative

      @media $mdMinus
        display none

    &-img-wrapper-1, &-img-wrapper-3, &-img-wrapper-4
      margin-bottom 124px

      @media $lgMinus
        margin-bottom 76px

      @media $laptopMinus
        margin-bottom 72px

    &-img-wrapper-1
      margin-left 273px

      @media $laptopMinus
        margin-left 120px

    &-img-wrapper-2
      align-self flex-start
      margin-left 459px

      @media $laptopMinus
        margin-left 700px

    &-img-wrapper-3
      margin-left -220px

      @media $laptopMinus
        margin-left -90px

    &-img-wrapper-4
      margin-left 121px

      @media $laptopMinus
        margin-left 81px

    &-img
      position relative
      margin-bottom 16px
      overflow hidden

      & img
        objectFit(100%, cover)

    &-img-1
      width 382px
      min-width 382px
      height 510px

      @media $laptopMinus
        width 288px
        min-width 288px
        height 384px

    &-img-2
      width 750px
      min-width 750px
      height 640px

      @media $laptopMinus
        width 645px
        min-width 645px
        height 496px

    &-img-3
      width 529px
      min-width 529px
      height 375px

      @media $laptopMinus
        width 530px
        min-width 530px
        height 329px

    &-img-4
      width 430px
      min-width 430px
      height 698px

      @media $lgMinus
        height 604px

      @media $laptopMinus
        height 468px

    &-arrows
      display none
      justify-content flex-end
      align-items center
      z-index 3
      padding 0 24px
      margin-bottom 32px

      @media $mdMinus
        display flex

      @media $sxs
        padding 0 12px
        margin-bottom 16px

    &-arrow
      display flex
      justify-content center
      align-items center
      width 33px
      height 14px

      &.swiper-button-disabled
        opacity .5

      @media $sxs
        width 28px
        height 12px

      svg
        fill $white
        width 33px
        height 14px

        @media $sxs
          width 28px
          height 12px

      &._prev
        margin-right 16px
        transform rotate(180deg)

    &-slider
      display none
      gap 24px
      overflow-x auto
      overflow-y hidden
      padding-left 24px
      padding-right 24px
      scrollbar-width none
      -ms-overflow-style none
      position relative

      @media $mdMinus
        display flex

      @media $sxs
        gap 12px
        padding-left 12px
        padding-right 12px

      &::-webkit-scrollbar
        display none

      &-item
        display flex
        flex-direction column

        &._item-1
          min-width 382px
          max-width 382px

          @media $sxs
            min-width 170px
            max-width 170px

          img
            height 510px

            @media $sxs
              height 228px

        &._item-2
          min-width 382px
          max-width 382px

          @media $sxs
            min-width 190px
            max-width 190px

          img
            height 640px

            @media $sxs
              height 285px

        &._item-3
          min-width 382px
          max-width 382px

          @media $sxs
            min-width 190px
            max-width 190px

          img
            height 640px

            @media $sxs
              height 285px

        &._item-4
          min-width 382px
          max-width 382px

          @media $sxs
            min-width 190px
            max-width 190px

          img
            height 640px

            @media $sxs
              height 285px

      &-img
        width 100%
        margin-bottom 16px
        overflow hidden

        & img
          objectFit(100%, cover)

        @media $sxs
          margin-bottom 8px

      &-span
        fontSzLh(16, 24)
        font-weight 400
        text-transform uppercase