.Building
  height 100vh
  width 100%
  position relative
  overflow hidden
  @media $laptopMinus
    // height 100vh
  @media $smMinus
    height 432px
  @media $sxs
    height 298px
  &__img 
    position absolute
    width 100%
    height 100%
    left 0
    top 0
    objectFit(100%, cover)
    &._day 
      z-index -1
    &._night
      z-index 1
      opacity 0