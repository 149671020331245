.MapPlace
  --pulse-width 15px
  position absolute
  z-index 3
  width 1.2%
  white-space normal
  @media $sxs
    --pulse-width 10px
  &__pin
    position relative
    width 100%
    background-image url('../img/map-pin.svg')
    background-size contain
    background-position center
    background-repeat no-repeat
    cursor pointer

    &:before
      display block
      content ''
      position absolute
      top calc(50% - 7px)
      left calc(50% - 7px)
      width 14px
      height 14px
      border-radius 50%
      box-shadow 0 0 0 rgba($green, .4)
      animation pulse-green 2s infinite
      @media $sxs
        width 8px
        height 8px
        top calc(50% - 4px)
        left calc(50% - 4px)

    &:after
      display block
      content ''
      padding-top 150%

  &__popup
    opacity 0
    pointer-events none
    position absolute
    bottom calc(100% + 8px)
    left 50%
    min-width 216px
    max-width 320px
    padding 20px
    text-align left
    font-size 20px
    line-height 26px
    font-weight 300
    word-wrap normal
    text-transform uppercase
    color $green
    background-color #fff
    transform translateX(-50%) scale(.9)
    transition all $transition

  &__popup-close
    display none
    position absolute
    right 0
    top 0
    width 50px
    height 100%
    background-image url('../img/close-green.svg')
    background-size 24px auto
    background-position center
    background-repeat no-repeat

  // &._accent
  //   .MapPlace__pin
  //     animation pulse-scale 2s infinite
  //     &::before
  //       display none
  &._active
    z-index 6
    .MapPlace
      &__pin
        background-image url('../img/map-pin-active.svg')
        animation-play-state paused
        &:before
          opacity 0
      &__popup
        pointer-events auto
        opacity 1
        transform translateX(-50%) scale(1)
